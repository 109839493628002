function Games() {
    return (
        <div className="Jovial">
            <header className="App-header">
                <h1>
                    Games
                </h1>
            </header>
        </div>
    )

}

export default Games;
