function NoPage() {
    return (
        <div>
            <header className="App-header">
                <h1>
                    404. No page found :(
                </h1>
            </header>
        </div>
    )

}

export default NoPage;
