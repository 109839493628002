function Music() {
    return (
        <div className="Jovial">
            <header className="App-header">
                <h1>Music</h1>
            </header>
            <body className="JovialBody">
                <div className="JovialSection">

                    <h2>Tabs</h2>
                    <ul>
                        <li>Just the two of us</li>
                        <li>Classical gas</li>
                    </ul>
                </div>
            </body>
        </div>
    )

}

export default Music;
